import {HomePage, HomePageProps} from "@blogs/pages";
import {SiteIndexHelpers} from "@blogs/deploy";
import Head from "next/head";

export const getStaticProps = SiteIndexHelpers.buildGetStaticProps({types: ['reviews']})


const Page = (props: HomePageProps) => (
  <>
    <Head>
      <meta name='ir-site-verification-token'
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
            value='99053160'/>
    </Head>
    <HomePage {...props}
              metaDescription='Discover your next dining adventure with ForkingFeedMe. We provide comprehensive reviews of restaurants worldwide to help you make informed food choices.'
              metaTitle='ForkingFeedMe | Best Restaurant Reviews and Recommendations Worldwide'/>
  </>
)

export default Page
